import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }
  
  gotoMarketplace() {
    window.open('https://aws.amazon.com/marketplace/seller-profile?id=e91b909c-abe6-4df8-b271-4da046a21edb');
  }
}
