import { Routes } from '@angular/router';

import { HomeOneComponent } from './home-one.component';
import { EulaComponent } from './eula/eula.component';
import { AppComponent } from './app/app.component';

export const HomeRoutes: Routes = [
  { path: '', component: HomeOneComponent },
  { path: 'eula', component: EulaComponent },
  { path: 'app/:id', component: AppComponent }
];