import { Component, OnInit, HostListener, HostBinding, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { WINDOW_PROVIDERS, WINDOW } from '../../../shared/helpers/window.helper';
import { Router } from '@angular/router';
import { ContentService, App } from 'app/shared/services/content.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isFixed;
  apps = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private content: ContentService
  ) { 
    this.content.getApps()
      .then((apps) => {
        this.apps = apps;
      });
  }

  ngOnInit() {
  }
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    if(offset > 10) {
      this.isFixed = true
    } else {
      this.isFixed = false;
    }
  }

  get appMenu(): HTMLElement {
    return this.document.getElementById('appMenu');
  }

  get isMobile(): boolean {
    return (this.document.getElementById('menu-toggle').style.display === 'block');
  }

  get isHome(): boolean {
    return (this.router.url === '/' || this.router.url === '');
  }

  getAppRoute(title:string):string {
    return this.content.getRoute(title);
  }

  @HostBinding("class.menu-opened") menuOpened = false;

  get appsHref():string {
    return (this.isHome)?'javascript:void(null)':'/#portfolio-section';
  }

  onAppsClick() {
    if (this.isHome) return;
    this.window.location.assign('/#portfolio-section');
  }

  toggleAppMenu() {
    if (this.appMenu.classList.contains('visible')) {
      this.hideAppMenu();
    } else {
      this.showAppMenu();
    }
  }

  showAppMenu() {
    if (!this.isMobile) {
      this.appMenu.classList.remove('hidden');
      this.appMenu.classList.add('visible');
    }
  }

  hideAppMenu() {
    this.appMenu.classList.remove('visible');
    this.appMenu.classList.add('hidden');
    this.toggleMenu();
  }

  toggleMenu() {
    this.menuOpened = !this.menuOpened
  }

}
