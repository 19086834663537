import { Component } from '@angular/core';
import { ActivatedRoute, Router, RouterEvent, NavigationEnd } from '@angular/router';
import { ContentService, Content } from 'app/shared/services/content.service';

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  app:any;
  blocks: Content[];

  constructor( 
    private route: ActivatedRoute, 
    private router: Router,
    private content: ContentService ) { 
      this.getApps();
      this.router.events.subscribe(
        (event:RouterEvent) => {
          if (event instanceof NavigationEnd) {
            window.scrollTo(0,0);
          }
        }
      );
  }

  getAppRoute(title:string):string {
    return this.content.getRoute(title);
  }
  
  getContent(body:any):string {
    return this.content.getBodyContent(body);
  }

  gotoApp(app:any) {
    this.router.navigate([`/app/${this.getAppRoute(app.fields.title)}`]);
  }

  async getApps() {
    this.route.params.subscribe(
      async (param:any) => {
        this.app = await this.content.getAppByRoute(param.id);
      }
    );
  }

}
