import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const CONFIG = {
  space: 'c4izdq85sky8',
  accessToken: 'wqsFSlnoJY6s5eHmnMW4LmXr98ygcQ4oLCSEWHdrg0c',

  contentTypeIds: {
    app: 'app',
    content: 'page'
  }
}

enum CONTENT_TYPE {
  FAQ = "FAQ",
  Block = "Block"
};

export interface App {
  id: string,
  version?: string,
  updated?: string,
  title: string,
  subtitle: string,
  route: string,
  body: string,
  image: string,
  awsUrl: string,
  reviewUrl?: string,
  githubUrl?: string,
  related: App[],
  content?: Content[]
};

export interface Content {
  id: string,
  title: string,
  content: string,
  type: CONTENT_TYPE
};
 

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  apps = [];
  content = [];

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }

  async getAppByRoute(route:string): Promise<App> {
    if (this.apps && this.apps.length > 0) {
      return this._findAppByTitle(route);
    } else {
      this.apps = await this.getApps();
      return this._findAppByTitle(route);
    }
  }

  async getApp(id:string): Promise<App> {
    if (this.apps && this.apps.length > 0) {
      return this.apps.filter(app => app.id === id)[0];
    } else {
      this.apps = await this.getApps();
      return this.apps.filter(app => app.id === id)[0];
    }
  }

  async getContent(query?: object): Promise<Content[]> {
    let content = await this._getContent(CONFIG.contentTypeIds.content, query);
    this.content = [];
    content.forEach((item:Entry<any>) => {
      this.content.push(this._createContentFromEntry(item));
    });
    return Promise.resolve(this.content);
  }

  async getApps(query?: object): Promise<Entry<App>[]> {
    let content = await this._getContent(CONFIG.contentTypeIds.app, query), related = [];
    this.apps = [];
    /*
    content.forEach((item:Entry<any>) => {
      if (item.fields.related) {
        related = this._getRelated(item.fields.related);
      }
      this.apps.push(this._createAppFromEntry(item));
    });
    return Promise.resolve(this.apps);
    */
   return Promise.resolve(content);
  }

  public getRoute(title:string):string {
    return title.toLowerCase().split(' ').join('-');
  }

  public getBodyContent(body:any):string {
    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          return `<div class="content-img"><img src="${node.data.target.fields.file.url}" alt="${node.data.target.fields.description}" /></div>`;
        }
      }
    };
    return documentToHtmlString(body,options);
  }
  
  private _getRelated(item?:Entry<any>[]):App[] {
    let result = [];
    if (item) {
      setTimeout(() => {
        item.forEach((item:Entry<any>) => {
          result.push(this._createAppFromEntry(item));
        });
      },500);
    }
    return result;
  }

  private _getAppContent(item?:Entry<any>[]):Content[] {
    let result = [];
    if (item) {
      setTimeout(() => {
        item.forEach((item:Entry<any>) => {
          result.push(this._createContentFromEntry(item));
        });
      },500);
    }
    return result;
  }

  private _createContentFromEntry(item:Entry<any>):Content {
    return {
      id: item.sys.id,
      title: item.fields.title,
      content: documentToHtmlString(item.fields.content),
      type: item.fields.type
    };
  }

  private _createAppFromEntry(item:Entry<any>):App {
    return {
      id: item.sys.id,
      version: item.fields.version,
      updated: item.sys.updatedAt,
      title: item.fields.title,
      subtitle: item.fields.subtitle,
      route: this.getRoute(item.fields.title),
      body: documentToHtmlString(item.fields.body),
      image: item.fields.image.fields.file.url,
      awsUrl: item.fields.awsUrl,
      reviewUrl: item.fields.reviewUrl,
      githubUrl: item.fields.githubUrl,
      related: this._getRelated(item.fields.related),
      content: this._getAppContent(item.fields.content)
    };
  }

  private _findAppByTitle(route:string): App {
    return this.apps.filter(app => app.fields.title.toLowerCase().split(' ').join('-') === route)[0]
  }

  private async _getContent(type:string, query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries(Object.assign({
      content_type: type
    }, query))
    .then(res => res.items);
  }
}
