import { Component, OnInit, OnDestroy } from '@angular/core';

const jq = (window as any).$, 
  connect = (window as any).connect,
  chatWin = jq('#section-chat');

@Component({
  selector: 'app-home-one',
  template: `<div class="landing">
  <app-header></app-header>
  <app-intro></app-intro>
  <app-portfolio></app-portfolio>
  <app-cta></app-cta>
  <app-services [backgroundGray]="true"></app-services>
  <app-footer></app-footer>
  </div>`
})
export class HomeOneComponent implements OnInit, OnDestroy {

  chatOn = false;
  successHandler:Function;
  failureHandler:Function;

  constructor(
  ) { }

  ngOnInit() {
    /*
    <button class="chat-button" mat-fab style="position:fixed;bottom:1em;right:1em;" (click)="initChat()" color="primary"><mat-icon>chat</mat-icon></button>
    this.chatOn = false;
    chatWin.hide();
    connect.ChatInterface.init({
      'containerId': 'chat-root'
    });
    */
  }

  initChat() {
    if (this.chatOn) {
      chatWin.hide();
      this.chatOn = false;
      return;
    }
    this.chatOn = true;
    this.successHandler = this.success.bind(this);
    this.failureHandler = this.failure.bind(this);
    chatWin.show();
    connect.ChatInterface.initiateChat({
      name: "Visitor",
      username: "admin",
      region: 'us-east-1',
      apiGatewayEndpoint: "https://8ir4uqx71m.execute-api.us-east-1.amazonaws.com/Prod",
      contactAttributes: JSON.stringify({
        "customerName": 'Visitor'
      }),
      contactFlowId: "238a72e2-29bf-40c8-8b78-3c5089b3c183",
      instanceId: "79023953-3ca7-4b1a-a700-842a5d9a344e"
    },this.successHandler, this.failureHandler);
  }

  success(e:any) {
    console.log('SUCCESS');
    console.log(e);

    e._eventHandlers['chat-disconnected'].push(((e:any) => {
      chatWin.hide();
      this.chatOn = false;
    }).bind(this));

  }

  failure(e:any) {
    chatWin.hide();
    this.chatOn = false;
  }

  ngOnDestroy() {
  }
  

}
