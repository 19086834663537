import { Component, OnInit, Input } from '@angular/core';
import { ContentService } from 'app/shared/services/content.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  active = {};

  @Input('backgroundGray') public backgroundGray;
  apps = [];
  constructor( private content: ContentService, private router: Router ) { }

  ngOnInit() {
    this._getContent();
  }

  activate(app:any) {
    this.active = {};
    this.active[app.title] = true;
  } 

  selectApp(title:string) {
    const titleRoute = title.toLowerCase().split(' ').join('-');
    this.router.navigate(['/app', titleRoute]);
  }

  private async _getContent() {
    this.apps = await this.content.getApps();
  }

}
